import { useQuery } from 'react-query';
import { useAuth } from '../useAuth';
import googleSheetsClient from './googleSheetsClient';

export interface SheetError {
  message: string;
  code: number;
  status: string;
}

export function useSheet({
  spreadsheetId,
  range,
}: {
  spreadsheetId?: string;
  range?: string;
}): { data?: any[][]; pending: boolean; error?: SheetError } {
  const { error: authError, signedIn, signingIn } = useAuth();

  const { data, isLoading, error } = useQuery<
    string[][],
    SheetError,
    any[][] | undefined,
    [string, string, string, boolean]
  >(
    ['sheet', spreadsheetId, range, signedIn],
    () =>
      googleSheetsClient
        .then(client =>
          client.sheets.spreadsheets.values
            .get({ spreadsheetId, range })
            .then(response => response.result.values)
            .catch(err => {
              if (err.status === 403) {
                throw {
                  ...err.result.error,
                  message:
                    'No permission to use sheet. It should be publicly viewable using sharing link or sign in to Google Drive.',
                };
              } else if (err.status === 404) {
                throw {
                  ...err.result.error,
                  message: 'Invalid sheet ID.',
                };
              } else {
                throw err.result.error;
              }
            }),
        )
        .catch(e => {
          throw e.details ? { message: e.details } : e;
        }),
    { enabled: !signingIn && !authError && !!spreadsheetId && !!range },
  );

  const authenticationError = authError && { status: authError.error, code: 0, message: authError.details };

  return { data, pending: isLoading || signingIn, error: error || authenticationError };
}
