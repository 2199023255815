const API_KEY = 'AIzaSyDE42UoBi_i8JaN0k8TRFLmhZ-xwqy5HaU';
const DISCOVERY_DOCS = [
  'https://sheets.googleapis.com/$discovery/rest?version=v4',
  'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest',
];
const CLIENT_ID = '969929085710-d89323d2pi80csd3be2hd07qob203nmg.apps.googleusercontent.com';

export const useSheetConfig = () => ({
  apiKey: API_KEY,
  clientId: CLIENT_ID,
  scope:
    'https://www.googleapis.com/auth/spreadsheets.readonly https://www.googleapis.com/auth/drive.metadata.readonly',
  discoveryDocs: DISCOVERY_DOCS,
});
