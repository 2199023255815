import { useEffect, useState } from 'react';
import googleSheetsClient from './sheets/googleSheetsClient';

export const useAuth = () => {
  const [signedIn, setSignedIn] = useState(false);
  const [currentUser, setCurrentUser] = useState<gapi.auth2.GoogleUser>();
  const [signingIn, setSigningIn] = useState(true);
  const [error, setError] = useState<{ error: string; details: string }>();

  const signIn = async () => {
    await googleSheetsClient;
    window.gapi.auth2.getAuthInstance().signIn();
  };

  const signOut = async () => {
    await googleSheetsClient;
    window.gapi.auth2.getAuthInstance().disconnect();
  };

  useEffect(() => {
    googleSheetsClient
      .then(() => {
        setSigningIn(false);
        setSignedIn(window.gapi.auth2.getAuthInstance().isSignedIn.get());
        setCurrentUser(window.gapi.auth2.getAuthInstance().currentUser.get());
        window.gapi.auth2.getAuthInstance().isSignedIn.listen(val => {
          setSignedIn(val);
          // setCurrentUser(null);
        });
        window.gapi.auth2.getAuthInstance().currentUser.listen(setCurrentUser);
      })
      .catch((e: { error: string; details: string }) => {
        setError(e);
      });
  }, []);

  return { signIn, signOut, signedIn, currentUser, signingIn, error };
};
