import { useContext } from 'react';
import { useQuery } from 'react-query';
import { ConfigContext } from '../ConfigContext';
import { useAuth } from '../useAuth';
import googleSheetsClient from './googleSheetsClient';
import { SheetError } from './useSheet';

export interface Legend {
  color: string;
  label: string;
  key: string;
}

const toRGBA = ({ red, green, blue }: gapi.client.sheets.Color): string =>
  `rgba(${Math.round(red * 255)},${Math.round(green * 255)},${Math.round(blue * 255)},var(--tw-text-opacity))`;

export const useLegendSheet = (sheetId?: string, range?: string) => {
  const config = useContext(ConfigContext);
  const { authError, signedIn } = useAuth();

  const spreadsheetId = sheetId || config.spreadsheetId;
  const legendRange = range || config.legendRange;

  const { data, isLoading, error } = useQuery<Legend[], SheetError, Legend[], [string, string, string, boolean]>(
    ['legend', spreadsheetId, legendRange, signedIn],
    () =>
      googleSheetsClient.then(client =>
        client.sheets.spreadsheets
          .get({
            spreadsheetId,
            ranges: legendRange,
            includeGridData: true,
          })
          .then(response => {
            const sheetData = response.result.sheets[0].data[0].rowData;
            const labels = sheetData[0]?.values || [];
            const keys = sheetData[2]?.values || [];

            return keys
              .filter(key => !!key.formattedValue)
              .map(({ effectiveFormat, formattedValue }, i) => ({
                color: toRGBA(effectiveFormat.backgroundColor),
                label: labels[i].formattedValue,
                key: formattedValue,
              }));
          })
          .catch(err => {
            if (err.status === 403) {
              throw {
                ...err.result.error,
                message:
                  'No permission to use sheet. It should be publicly viewable using sharing link or sign in to Google Drive.',
              };
            } else if (err.status === 404) {
              throw {
                ...err.result.error,
                message: 'Invalid sheet ID.',
              };
            } else {
              throw err.result.error;
            }
          }),
      ),
    { enabled: !!spreadsheetId && !!legendRange && !authError },
  );

  const authenticationError = authError && { status: authError.error, code: 0, message: authError.details };

  return { data, pending: isLoading, error: error || authenticationError };
};
