import React, { ComponentPropsWithoutRef, ReactNode } from 'react';
import tw from 'twin.macro';

interface ButtonProps {
  children: ReactNode;
  inversed?: boolean;
  size?: 'medium' | 'small';
  variant?: 'primary' | 'outlined';
}

export const Button = ({
  children,
  inversed,
  size = 'medium',
  variant = 'primary',
  ...props
}: ButtonProps & ComponentPropsWithoutRef<'button'>) => (
  <button
    tw="focus:outline-none rounded font-medium"
    css={[
      { medium: tw`py-1 px-3`, small: tw`py-1 px-1 text-xs` }[size],
      {
        primary: {
          ...tw`bg-blue-600 hover:bg-blue-700 text-white border-transparent`,
          borderWidth: 3,
        },
        outlined: inversed
          ? tw`border-2 border-white hover:(border-gray-300 bg-blue-400 bg-opacity-25)`
          : tw`border-2 border-blue-500 text-blue-500 hover:(border-transparent bg-blue-500 text-white)`,
      }[variant],
    ]}
    {...props}
  >
    {children}
  </button>
);
