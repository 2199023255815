/// <reference types="gapi" />
/// <reference types="gapi.auth2" />
/// <reference types="gapi.client.sheets" />
/// <reference types="gapi.client.drive" />

import { useSheetConfig } from './useSheetConfig';

const googleSheetsClient = new Promise<typeof gapi.client>((resolve, reject) => {
  if (typeof window !== `undefined`) {
    const api = window.gapi;
    api.load('client', {
      onerror: err => {
        console.log('Error loading Google API Client', err);
        reject(err);
      },
      callback: async () => {
        try {
          await api.client.init(useSheetConfig());
          await Promise.all([api.client.load('sheets', 'v4'), api.client.load('drive', 'v3')]);
          resolve(api.client);
        } catch (e) {
          reject(e);
        }
      },
    });
  }
});

export default googleSheetsClient;
